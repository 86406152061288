import '../styles/index.scss';
import '../img/art-0023-u1718-fr.jpg';
import '../img/under-construction.png';
import '../img/under-construction-2.png';
import '../img/logo.png';
import '../img/relja_z.jpg';
import '../img/ana_a.jpg';
import '../img/ivana_i.jpg';
import '../img/marko_b.jpg';
import '../img/mihailo_m.jpg';
import '../img/nemanja_r.jpg';
import '../img/dunja_m.jpg';
import '../img/veljko_o.jpg';

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}

console.log('webpack starterkit');
